import { Header } from './components/header';
import { ResetMpin } from './components/reset-mpin';

export function ResetMpinIndex() {
    return (
        <>
            <Header />
            <div className='body-wrapper login-body-wrapper'>
                <div className='body-container'>
                    <ResetMpin />
                </div>
            </div>
        </>
    )
}