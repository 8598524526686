import React from 'react';
import { Header } from './components/header';
import { EmiTrackConvert } from './components/emi-track-convert';
import { ApiCalls } from '../common/apiCalls';

export function EmiTrackingIndex(props) {

    const { transactionStatusDetails, loading } = ApiCalls();

    return (
        <>
            <Header />
            <div className="body-wrapper has-sticky-header">
                <div className="secondary-header-wrapper">
                    <div>Convert and track all your EMIs here</div>
                </div>
                <div className="body-container">
                    <EmiTrackConvert transactionStatusDetails={transactionStatusDetails} />
                </div>
            </div>
        </>
    )
}
