import React, { useState, useEffect } from 'react'
import { Header } from './components/header';
import { ForgotMpin } from './components/forgot-mpin';

export function ForgotMpinIndex({showToast}) {

    const [headerTitle, setheaderTitle] = useState();
    const [isBack, setBackState] = useState(true);

    const updateHeaderTitle = (title, back) => {
        setheaderTitle(title);
        setBackState(back);
    }
    
  useEffect(() => {
    updateHeaderTitle('Forgot M-PIN', true);
  }, []);

    return (
        <>
            <Header headerTitle={headerTitle} isBack={isBack} />
            <div className='body-wrapper has-fixed-footer'>
                <div className='body-container'>
                    <ForgotMpin updateHeaderTitle={updateHeaderTitle} showToast={showToast} />
                </div>
            </div>
        </>
    )
}