import React, { useRef, useState } from 'react';

export function EnterOtp({ label, otpLength, setValue, controlType, showOtpError }) {
  const [otpValues, setOtpValues] = useState(Array(otpLength).fill('')); // Initialize an array to store OTP digits
  const inputRefs = useRef([]);

  // Handle when a key is pressed
  const handleChange = (event, index) => {
    const value = event.target.value;
    if (!isNaN(value)) { // Ensure that only numbers are allowed
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);
      updateOtp(newOtpValues.join('')); // Pass the OTP value to parent component
      // Move to the next input if a digit is entered
      if (value && index < otpLength - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Handle data on key press
  const handleKeyDown = (event, index) => {
    const value = event.target.value;
    if (event.key === 'Backspace') {
      if (otpValues[index] === '') {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = ''; // Clear the current input field
        setOtpValues(newOtpValues);
        updateOtp(newOtpValues.join(''));
      }
    } else if (event.key === 'ArrowLeft') {
      if (index) {
        inputRefs.current[index - 1].focus();
      }
    } else if (event.key === 'ArrowRight') {
      if (index < (otpLength - 1)) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      if (index < (otpLength - 1) && value) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
  // Handle data on key up
  const handleKeyUp = (event, index) => {
    if (event.key === 'Enter') { // condition for pressing enter key
      updateOtp(otpValues.join(''), true);
    }
  }

  const updateOtp = (value, isSubmit) => {
    const otpData = {
      value: value,
      isValid: (value?.length === otpLength) ? true : false,
      isSubmit: isSubmit
    }
    setValue(otpData);
  }

  return (
    <div className="control-wrapper">
      {label &&
        <label className='control-label-wrapper pb-2 d-block text-center'><span>{label}</span>
          {/* <button className='btn btn-link change-btn' onClick={toggleOtpPopup}>Change</button> */}
        </label>
      }
      <div className="set-pin-item-container">
        {otpValues.map((digit, index) => (
          <div className="pin-item" key={index}>
            <input
              type={controlType?controlType:'text'}
              inputMode={'numeric'}
              maxLength="1"
              value={otpValues[index]}
              onChange={(event) => handleChange(event, index)} onKeyDown={(event) => handleKeyDown(event, index)} onKeyUp={(event) => handleKeyUp(event, index)}
              ref={(el) => (inputRefs.current[index] = el)}
              className="form-control"
            />
          </div>
        ))}
      </div>      
      {showOtpError && <p className='error-text'>{showOtpError}</p>}
    </div>
  );
}
