import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/styles.scss';
import moment from 'moment';

function Transactions(props) {
    const { unbilledTransactions = [] } = props;
    const navigate = useNavigate();

    const handleTransactionList = () => {
        navigate('/unbilled-transactions')
    }

    const handleEmiTransaction = (externalTransactionId) => {
        // navigate('/transactions-emi', { state: { externalTransactionId }}
        navigate('/emi-tracking')
    };

    return (
        <div className="transactions-wrapper">
            <div className="transactions-header">
                <h4 className="transactions-title">Unbilled Transactions</h4>
                <div className="transactions-header-actions ms-auto">
                    <button className="btn" onClick={handleTransactionList}><i className="fa-solid fa-chevron-right"></i></button>
                    {/* <div className="item-arrow-wrapper"><i className="feather-chevron-right" ></i></div> */}
                </div>
            </div>
            <div className="transactions-body">
                {unbilledTransactions.map((details, index) => (
                    <div key={index} className="transactions-item-wrapper">
                        <div className="transactions-item-container">
                            <div className="item-icon-wrapper">
                                <div className={'item-icon-container ' + (details?.transactionType === 'DEBIT' ? "debit-transaction" : "credit-transaction")}>
                                    <i className={details?.transactionType === 'DEBIT' ? 'fa-minus fa-solid' : 'fa-plus fa-solid'}></i>
                                </div>
                            </div>
                            <div className="item-text-wrapper">
                                <div className="item-name">{details?.description}</div>
                                <div className="item-time">{moment(details?.datetime).format('MMM Do YYYY, h:mm:ss A')}</div>
                            </div>

                            <div className="item-value-wrapper">
                                {details.isEmiEligible === true && (
                                    <button className='btn emi-icon' onClick={() => handleEmiTransaction(details.externalTransactionId)}>
                                        <img className='img-fluid' src={require('../../assets/img/easy_emi_logo.png')} alt="Logo" />
                                    </button>
                                )}
                                <div className="item-total-value">
                                    <i className="fa-solid fa-indian-rupee-sign"></i>{details?.amount}

                                </div>

                            </div>
                            {/* <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Transactions