import React from 'react'

import { Header } from './components/header';
import { SetupNewPIN } from './components/atm-pin';
import { ApiCalls } from '../common/apiCalls';

export function PinResetIndex() {
    const { setNewPinApiCall } = ApiCalls();
    return (
        <>
            <Header />
            <div className='body-wrapper login-body-wrapper'>
                <div className='body-container'>
                    <SetupNewPIN setNewPinApiCall={setNewPinApiCall} />
                </div>
            </div>
        </>
    )
}
