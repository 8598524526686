import React, { useState } from "react";
import { EmiTrack } from "./emi-track";
import { EmiConvert } from "./emi-convert";

export function EmiTrackConvert(props) {
  const [activeTab, setActiveTab] = useState("EmiConvert");

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="tab-main-section">
      <ul className="nav nav-pills nav-justified custom-nav" role="tablist">
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link ${activeTab === "EmiConvert" ? "active" : ""}`}
            onClick={() => handleTabChange("EmiConvert")}
          >
            Convert Transactions
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link ${activeTab === "EmiTrack" ? "active" : ""}`}
            onClick={() => handleTabChange("EmiTrack")}
          >
            Track EMIs
          </button>
        </li>
      </ul>
      <div className="tablist">
        {activeTab === "EmiConvert" && <EmiConvert />}
        {activeTab === "EmiTrack" && <EmiTrack />}
      </div>
    </div>
  );
}
