import React from 'react'
import { useNavigate } from "react-router-dom";

import '../../assets/styles/styles.scss';

export function Header(props) {
  const { statementData = [] } = props;
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  }

  const formatDatePart = (dateObj) => {
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('en-US', { month: 'short' });
    const year = dateObj.getFullYear();
    const ordinal = getOrdinal(day);
    return `${day}${ordinal} ${month} ${year}`;
  }

  const getOrdinal = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  const formatDate = (startDateString) => {
    const startDateObj = new Date(startDateString);
    const endDateObj = calculateEndDate(startDateObj);
    const formattedStartDate = formatDatePart(startDateObj);
    const formattedEndDate = formatDatePart(endDateObj);
    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  const calculateEndDate = (startDateObj) => {
    const endDateObj = new Date(startDateObj);
    endDateObj.setMonth(startDateObj.getMonth() + 1);
    endDateObj.setDate(startDateObj.getDate() - 1);
    return endDateObj;
  }

  return (
    <div className="header-wrapper back-header">
      <div className="header-container">
        <div className="header-menu-wrapper">
          <button className="btn" onClick={handleBackButtonClick}>
            <i className="feather-arrow-left"></i>
          </button>
        </div><div className="header-title">
          <div>Latest Statement</div>
          <div className="header-title-desc">{formatDate(statementData)}</div>
        </div>
        <div className="header-right-wrapper">
          <button className="btn">
            <i className="fas fa-download"></i>
          </button>
        </div>
      </div>
    </div>
  )
}
