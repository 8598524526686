import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ApiCalls } from '../../common/apiCalls';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export function EmiConvert() {
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(true);
    // const { transactionStatusDetails } = ApiCalls();

    // console.log("Transaction Status Details: ", transactionStatusDetails);

    // useEffect(() => {
    //     if (transactionStatusDetails) {
    //         setLoading(false);
    //     }
    // }, [transactionStatusDetails]);

    // if (loading) {
    //     return (
    //         <div className="loader">
    //             <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '40%' }} />
    //         </div>
    //     );
    // }

    // if (!transactionStatusDetails || !transactionStatusDetails.length) {
    //     return (
    //         <div className="track-emi-tab">
    //             <h3>Don't want to pay it all at once</h3>
    //             <p>Convert unbilled transactions of above 2500 to easy EMIs. Some transactions are not eligible. <a href="">Learn more</a></p>
    //         </div>
    //     );
    // }

    // const unbilledTransactionStatusDetails = Object.entries(transactionStatusDetails)
    //     .filter(([_, transaction]) => transaction.billedStatus === "UNBILLED");

    // const handleTransactionClick = (transaction) => {
    //     navigate('/transactions-emi', {
    //         state: {
    //             description: transaction?.description,
    //             transactionDate: transaction?.transactionDate,
    //             amount: transaction?.amount,
    //             externalTransactionId: transaction?.externalTransactionId,
    //             billedStatus: transaction?.billedStatus,
    //             authorizationStatus: transaction?.authorizationStatus,
    //         }
    //     });
    // };

    return (
                    <div className="track-emi-tab">
                <h3>Don't want to pay it all at once</h3>
                <p>Convert unbilled transactions of above 2500 to easy EMIs. Some transactions are not eligible. <a href="">Learn more</a></p>
            </div>
        
        // <div className="unbilled-transactions-container">
        //     {unbilledTransactionStatusDetails.map(([date, transactions], index) => (
        //         <div key={index}>
        //             <div className="unbilled-transactions-date">{date}</div>
        //             {transactions.map((transaction, innerIndex) => (
        //                 <div key={innerIndex} className="transaction-card-container" onClick={() => handleTransactionClick(transaction)}>
        //                     <div className="d-flex">
        //                         <div className={`transaction-icon-circle ${transaction.transactionType === "DEBIT" ? 'debit' : 'credit'}`}>
        //                             {transaction.description ? transaction.description.charAt(0).toUpperCase() : ''}
        //                         </div>
        //                         <div>
        //                             <h2 className="transaction-container-heading">{transaction?.description ? transaction.description.split('|')[0].trim() : ''}</h2>
        //                             <p className="transaction-container-time">{moment(transaction.transactionDate).format('Do MMM YYYY hh:mm A')}</p>
        //                         </div>
        //                     </div>
        //                     <div>
        //                         <div>
        //                             {transaction.isEMIEligible !== false && (
        //                                 <img className='logo' src={require('../../assets/img/easy_emi_logo.png')} alt="Logo" />
        //                             )}
        //                         </div>
        //                         <div className="unbilled-transaction-container-amount">
        //                             {transaction.transactionType === "CREDIT" && "+ "}
        //                             &#8377;{parseFloat(transaction.amount).toFixed(2)} &nbsp;
        //                             <i className="fa-solid fa-chevron-right unbilled-transaction-container-amount-i"></i>
        //                         </div>
        //                     </div>
        //                 </div>
        //             ))}
        //         </div>
        //     ))}
        // </div>
    );
}
