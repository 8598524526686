import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from './components/header';
import { Menu } from './components/menu.js';
import { Transactions } from './components/transactions.js';
import { ApiCalls } from '../common/apiCalls.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CommonValues } from "../../pages/common/commonValues.js";

export function StatementDetailsIndex() {
    const location = useLocation();
    const { loading } = ApiCalls();

    const statementData = location.state && location.state.statementData;
    const transactions = location.state && location.state.transactions;

    const { loginUserData } = CommonValues();
    useEffect(() => {
    }, [loginUserData]);

    return (
        <>
            {loading ? (
                <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '45%' }} />
                </div>
            ) : (
                <>
                    <Header statementData={statementData.startDate} />
                    <div className="body-wrapper has-sticky-header">
                        <div className="body-container">
                            <Menu statementData={statementData} />
                            <Transactions transactions={transactions} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
