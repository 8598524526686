export const balanceDetails = {
    "status": "success",
    "data": [
        {
            "productId": "GENERAL",
            "balance": "987729.0",
            "lienBalance": "2392.0"
        }
    ]
}

export const cardDetails = {
    "card": {
        "id": "",
        "kitNo": "4000000782",
        "cardNo": "4666560200300782",
        "partialCardNo": "0782",
        "isPinSetup": true,
        "type": "VIRTUAL",
        "networkType": "VISA",
        "status": "ALLOCATED",
        "expiryDate": "2602",
        "issueDate": "2024-08-01"
    },
    "customer": {
        "name": "SANTOSH VARUN",
        "dob": "01012000"
    }
}

export const cardList = {
    "status": "success",
    "data": [
        {
            "kitNo": "93440000315",
            "cardNo": "3561670000001125",
            "partialCardNo": "1125",
            "isPinSetup": false,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000324",
            "cardNo": "3561670000001134",
            "partialCardNo": "1134",
            "isPinSetup": true,
            "cardType": "PHYSICAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000332",
            "cardNo": "3561670000001142",
            "partialCardNo": "1142",
            "isPinSetup": false,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000341",
            "cardNo": "3561670000001151",
            "partialCardNo": "1151",
            "isPinSetup": true,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000349",
            "cardNo": "3561670000001159",
            "partialCardNo": "1159",
            "isPinSetup": true,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "LOCKED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000351",
            "cardNo": "3561670000001161",
            "partialCardNo": "1161",
            "isPinSetup": true,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "15870000051",
            "cardNo": "6529560000000515",
            "partialCardNo": "0515",
            "isPinSetup": false,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2811",
            "cardIssueDate": null
        }
    ]
}

export const transactionDetails = {
    "status": "success",
    "data": [
        {
            "id": "",
            "amount": 1,
            "date": "2024-04-05",
            "time": "07:15:09",
            "datetime": 1712301309000,
            "externalId": "010005000206271211500000043327400000570000",
            "internalId": "403155600619797",
            "origin": "ECOM",
            "kitNo": "93440000324",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "ECOM",
            "subCategory": "ECOM",
            "universalCurrencyAmount": "1.00"
        },
        {
            "id": "",
            "amount": 1,
            "date": "2024-04-05",
            "time": "09:12:38",
            "datetime": 1712308358000,
            "externalId": "010005000506271211500000043327400000570000",
            "internalId": "403155600619797",
            "origin": "ECOM",
            "kitNo": "93440000324",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "ECOM",
            "subCategory": "ECOM",
            "universalCurrencyAmount": "1.00"
        },
        {
            "id": "",
            "amount": 1,
            "date": "2024-04-05",
            "time": "09:13:18",
            "datetime": 1712308398000,
            "externalId": "010005000606271211500000043327400000570000",
            "internalId": "403155600619797",
            "origin": "ECOM",
            "kitNo": "93440000324",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "ECOM",
            "subCategory": "ECOM",
            "universalCurrencyAmount": "1.00"
        },
        {
            "id": "",
            "amount": 2245,
            "date": "2024-03-12",
            "time": "05:48:05",
            "datetime": 1710222485000,
            "externalId": "010074921106271211500000043327400000570000",
            "internalId": "407211607471",
            "origin": "POS",
            "kitNo": "93440000349",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "2245.00"
        },
        {
            "id": "",
            "amount": 2171,
            "date": "2024-03-12",
            "time": "05:49:04",
            "datetime": 1710222544000,
            "externalId": "010051297006271211500000043327400000570000",
            "internalId": "407211674624",
            "origin": "POS",
            "kitNo": "93440000349",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "2171.00"
        },
        {
            "id": "",
            "amount": 2322,
            "date": "2024-02-23",
            "time": "11:00:08",
            "datetime": 1708686008000,
            "externalId": "S23022024523",
            "origin": "LOAN",
            "description": "EMI CONVERSION | S23022024523",
            "type": "CREDIT",
            "category": "EMI_CONVERSION",
            "subCategory": "EMI_CONVERSION"
        },
        {
            "id": "",
            "amount": 2446,
            "date": "2024-02-19",
            "time": "10:55:23",
            "datetime": 1708340123000,
            "externalId": "S19022024509",
            "origin": "LOAN",
            "description": "EMI CONVERSION | S19022024509",
            "type": "CREDIT",
            "category": "EMI_CONVERSION",
            "subCategory": "EMI_CONVERSION"
        },
        {
            "id": "",
            "amount": 2392,
            "date": "2024-02-14",
            "time": "11:56:32",
            "datetime": 1707911792000,
            "externalId": "010046023106271211500000043327400000570000",
            "internalId": "404517892544",
            "origin": "POS",
            "kitNo": "93440000349",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "2392.00"
        },
        {
            "id": "",
            "amount": 47,
            "date": "2024-02-14",
            "time": "11:56:33",
            "datetime": 1707911793000,
            "externalId": "5089899100907235338",
            "internalId": "699987962",
            "origin": "WEB",
            "description": "REWARDS_CASHBACK",
            "type": "CREDIT",
            "category": "DIRECT_CREDIT",
            "subCategory": "REWARDS_CASHBACK"
        },
        {
            "id": "",
            "amount": 2446,
            "date": "2024-02-14",
            "time": "11:57:37",
            "datetime": 1707911857000,
            "externalId": "010013312806271211500000043327400000570000",
            "internalId": "404517567445",
            "origin": "POS",
            "kitNo": "93440000349",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "2446.00"
        },
        {
            "id": "",
            "amount": 48,
            "date": "2024-02-14",
            "time": "11:57:38",
            "datetime": 1707911858000,
            "externalId": "8051743691028775374",
            "internalId": "533829524",
            "origin": "WEB",
            "description": "REWARDS_CASHBACK",
            "type": "CREDIT",
            "category": "DIRECT_CREDIT",
            "subCategory": "REWARDS_CASHBACK"
        },
        {
            "id": "",
            "amount": 2322,
            "date": "2024-02-14",
            "time": "11:58:38",
            "datetime": 1707911918000,
            "externalId": "010074425406271211500000043327400000570000",
            "internalId": "404517857975",
            "origin": "POS",
            "kitNo": "93440000349",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "2322.00"
        },
        {
            "id": "",
            "amount": 46,
            "date": "2024-02-14",
            "time": "11:58:39",
            "datetime": 1707911919000,
            "externalId": "6579934192146052657",
            "internalId": "620477781",
            "origin": "WEB",
            "description": "REWARDS_CASHBACK",
            "type": "CREDIT",
            "category": "DIRECT_CREDIT",
            "subCategory": "REWARDS_CASHBACK"
        },
        {
            "id": "",
            "amount": 2392,
            "date": "2024-02-14",
            "time": "14:09:22",
            "datetime": 1707919762000,
            "externalId": "S14022024493",
            "internalId": "478836240",
            "origin": "LOAN",
            "description": "EMI CONVERSION | S14022024493",
            "type": "CREDIT",
            "category": "EMI_CONVERSION",
            "subCategory": "EMI_CONVERSION"
        },
        {
            "id": "",
            "amount": 500,
            "date": "2024-02-14",
            "time": "18:30:00",
            "datetime": 1707935400000,
            "externalId": "493a407653e04bc7a0b2550de840bdec_FEES",
            "internalId": "840158692",
            "description": "LATE_PAYMENT_FEE",
            "merchantName": "LATE PAYMENT FEES",
            "type": "DEBIT",
            "category": "FEES",
            "subCategory": "LATE_PAYMENT_FEE"
        },
        {
            "id": "",
            "amount": 90,
            "date": "2024-02-14",
            "time": "18:30:00",
            "datetime": 1707935400000,
            "externalId": "493a407653e04bc7a0b2550de840bdec_FEES_ST",
            "internalId": "781815222",
            "description": "LATE_PAYMENT_FEE_TAX",
            "merchantName": "LATE PAYMENT FEES SERVICE TAX",
            "type": "DEBIT",
            "category": "SERVICETAX",
            "subCategory": "LATE_PAYMENT_FEE_TAX"
        }
    ]
}

export const cvvDetails = {
    "status": "success",
    "data": {
        "cvv": "299"
    }
}

export const limitDetails = {
    "status": "success",
    "data": {
        "totalLimit": "100000.00",
        "availableLimit": "985337.00",
        "utilizedLimit": "-885337.00"
    }
}

//GET Call to identify the due amount and details for a user card
export const dueDetails = {
    "status": "success",
    "data": {
        "minDue": "131.90",
        "totalDue": "0.00",
        "dueDate": "2024-01-06",
        "paymentMade": "141.00",
        "unbilledAmount": "12166.00",
        "interestAccumulated": "0.00",
        "totalOutStandingAmount": "7503.00",
        "currentStatementDate": "2024-03-21",
        "nextStatementDate": "2024-03-04",
        "unpaidMinDue": "0.00",
        "unpaidTotalDue": "2497.00",
        "unpaidMinDueBeforeDue": "131.90",
        "unpaidTotalDueBeforeDue": "2638.00",
        "excessPayment": "0.00",
        "postedEmi": {
            "emiTotalAmount": "0.00",
            "principal": "0.00",
            "interest": "0.00",
            "otherCharges": "0.00"
        },
        "unpaidPostedEmi": {
            "emiTotalAmount": "0.00",
            "principal": "0",
            "interest": "0",
            "otherCharges": "0"
        },
        "previousUnpaidEmi": {
            "emiTotalAmount": "0.00",
            "principal": "0",
            "interest": "0",
            "otherCharges": "0"
        },
        "feeDetails": [
            {
                "feeType": "LATEPAYMENT",
                "unPaidBilledFee": "0.00",
                "unPaidUnBilledFee": "500.00",
                "unPaidBilledFeeTax": "0.00",
                "unPaidUnBilledFeeTax": "90.00"
            }
        ],
        "lastStatementAmount": "2638.00",
        "repaymentPercentage": null
    }
}

export const statementDetails = {
    "status": "success",
    "data": {
        "transactions": [
            {
                "date": "2024-02-14 06:22:13",
                "type": "DEBIT",
                "externalId": "010035439706271211500000043327400000570000",
                "internalId": "404511502421",
                "amount": 2691,
                "category": "POS",
                "subCategory": "POS",
                "description": "ABCRESTAURANTCOIMBATORE               IN",
                "status": "SUCCESS"
            },
            {
                "date": "2024-02-14 06:22:14",
                "type": "CREDIT",
                "externalId": "8303783561705693390",
                "internalId": "839792370",
                "amount": 53,
                "category": "DIRECT_CREDIT",
                "subCategory": "REWARDS_CASHBACK",
                "description": "REWARDS_CASHBACK",
                "status": "SUCCESS"
            }
        ],
        "statement": {
            "amount": 0,
            "totalCreditAmount": 0,
            "totalDebitAmount": 0,
            "minimumDueAmount": 131.9,
            "statementDate": "2023-12-04 00:00:00",
            "startDate": "2023-11-04 00:00:00",
            "customerDueDate": "2023-12-06 18:20:00",
            "lastStatementBalance": 2638,
            "paymentStatus": "PAID",
            "finance": {
                "interest": 0,
                "tax": 0,
                "fees": 0
            },
            "purchase": {
                "amount": 0,
                "cash": 0
            },
            "emi": {
                "debit": 0,
                "principle": 0,
                "interest": 0,
                "otherCharges": 0,
                "limitBlockedAmount": 0
            }
        }
    }
}

export const updateStatementResponse = {
    "status": "success",
    "data": {
        "status": true
    }
}

export const setNewPinResponse = {
    "status": "success",
    "data": {
        "status": true
    }
}

export const billingDates = {
    "status": "success",
    "data": [
        {
            "from": 7,
            "to": 25,
            "eligible": false
        },
        {
            "from": 15,
            "to": 3,
            "eligible": true
        },
        {
            "from": 20,
            "to": 8,
            "eligible": true
        },
        {
            "from": 21,
            "to": 9,
            "eligible": true
        },
        {
            "from": 25,
            "to": 15,
            "eligible": true
        }
    ]
}