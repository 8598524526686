import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/styles.scss';

import { fetchUpdateStatement } from '../../../common/cms-sdk';

export function Menu(props) {
    const { currentStatementDate } = props;
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [buttonClick, setButtonClick] = useState(false);
    const [nextBillingDueDate, setNextBillingDueDate] = useState("");
    const [formattedCurrentDate, setFormattedCurrentDate] = useState("");
    const [selectedBillingDate, setSelectedBillingDate] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        if (currentStatementDate) {
            const currentDate = new Date(currentStatementDate);
            const nextDueDate = new Date(currentDate);
            nextDueDate.setDate(nextDueDate.getDate() + 18);

            if (nextDueDate.getMonth() !== currentDate.getMonth()) {
                nextDueDate.setMonth(nextDueDate.getMonth() + 1);
            }

            const formattedCurrent = getDateWithOrdinal(currentDate.getDate());
            const formattedNextDueDate = getDateWithOrdinal(nextDueDate.getDate());

            setFormattedCurrentDate(formattedCurrent);
            setNextBillingDueDate(formattedNextDueDate);
        }
    }, [currentStatementDate]);

    async function handleConfirmUpdateBillingDate() {
        try {
            const updateStatementResponse = await fetchUpdateStatement({ "stmtDate": selectedBillingDate });
            if (updateStatementResponse) {
                setShowSuccessMessage(true);
                setTimeout(() => {
                    setShowSuccessMessage(false);
                    navigate(-1);
                }, 3000);
            }
            // else {
            // }
        } catch (error) {
            console.log("Error updating Statement:", error);
        }
    }

    const handleRadioClick = (billingDate) => {
        setButtonClick(true);
        setSelectedBillingDate(billingDate);
    }

    function getDateWithOrdinal(date) {
        const n = parseInt(date);
        if (n >= 11 && n <= 13) {
            return n + "th";
        }
        switch (n % 10) {
            case 1:
                return n + "st";
            case 2:
                return n + "nd";
            case 3:
                return n + "rd";
            default:
                return n + "th";
        }
    }

    return (
        <div className="body-wrapper has-padding has-fixed-footer has-fixed-footer-text">
            <div className="body-container">
                <div className="form-group">
                    <label className="form-label fw-600">Current billing cycle:</label>
                    {currentStatementDate ? (
                        <p>Billing cycle starts on {formattedCurrentDate} of every month. {formattedCurrentDate > nextBillingDueDate ? 'Pay by ' + nextBillingDueDate + ' of the next month.' : 'Pay by ' + nextBillingDueDate + ' of the same month.'}</p>
                    ) : (
                        <p>Billing cycle is not chosen. Please choose a billing cycle</p>
                    )}
                </div>
                <div className="form-group">
                    <label className="form-label fw-600">Upcoming billing cycles you can choose from:</label>
                    <div>
                        {props.billingDates.map((dateObj, index) => (
                            <div key={index} className="custom-radio-item-wrapper">
                                <label className="form-check-radio">
                                    <input
                                        type="radio"
                                        name="billingCycle"
                                        id={`billingCycle${index + 1}`}
                                        className="form-check-input-radio"
                                        onClick={() => handleRadioClick(dateObj?.billingDate)}
                                    // disabled={props.dueAmount > 0 || !(dateObj?.eligible)}
                                    />
                                    <span
                                        htmlFor={`billingCycle${index + 1}`}
                                        className="form-check-label-radio"
                                    >
                                        Billing cycle starts on {getDateWithOrdinal(dateObj?.billingDate)} of every month. {dateObj?.billingDate > dateObj?.paymentDate ? 'Pay by ' + getDateWithOrdinal(dateObj?.paymentDate) + ' of the next month.' : 'Pay by ' + getDateWithOrdinal(dateObj?.paymentDate) + ' of the same month.'}
                                    </span>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                {showSuccessMessage && (<div className="text-success">
                    <p>Billing statement date is successfully updated !</p>
                </div>)}
            </div>


            <div className='page-footer-wrapper'>
                {props.dueAmount > 0 && (
                    <div className="alert-item-wrapper warning-alert mb-2">
                        <div className="alert-item-container">
                            {/* <div className="alert-icon"><i className="feather-alert-circle"></i></div> */}
                            <div className="alert-text text-center"><small>Please clear your total outstanding amount to change your billing cycle.</small></div>
                        </div>
                    </div>
                )}
                <div className='page-footer-container'>
                    <button className="btn btn-primary btn-lg btn-circular page-footer-btn" onClick={handleConfirmUpdateBillingDate} disabled={!(buttonClick) || props.dueAmount > 0}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    )
}
