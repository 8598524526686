import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { EnterOtp } from '../../../common/enter-otp/enter-otp';
import { CommonValues } from "../../common/commonValues.js";
import { otpSend, forgotMpin } from '../../../common/cms-sdk/index';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export function ForgotMpin({ updateHeaderTitle, showToast }) {
  const { loginUserData } = CommonValues();
  const navigate = useNavigate();

  const [isIntialLoader, setIntialLoader] = useState(true);
  const [isOtpPopup, setOtpPopup] = useState(true);
  const [showOtpPopup, setShowOtpPopup] = useState(true);
  const [otpData, setOtpData] = useState();
  const [otpId, setOtpId] = useState('');

  const cardNoRefs = useRef();
  const [partialCardNo, setPartialCardNo] = useState('');

  const cardExpiryMonthRefs = useRef();
  const cardExpiryYearRefs = useRef();
  const [cardExpiryMonth, setCardExpiryMonth] = useState('');
  const [cardExpiryYear, setCardExpiryYear] = useState('');
  const [showExpiryDateError, setExpiryDateError] = useState('');

  const [mpinData, setMpinData] = useState();
  const [reMpinData, setReMpinData] = useState();
  const [showOtpError, setOtpError] = useState('');
  const [validData, setValidData] = useState(false);

  // const [toasts, setToasts] = CommonValues();

  useEffect(() => {
    handleOtp();
  }, [loginUserData]);

  const handleOtp = useCallback(async () => {
    if (loginUserData?.mobileNumber) {
      const otpResp = await otpSend(loginUserData.mobileNumber, 'FORGOT_MPIN');
      // const otpResp = {
      //   "otpId": "6c8f1a93-a54f-4c2b-b493-10ced5d75d53",
      //   "otp": "155006"
      // };
      if (otpResp.otpId) {
        setOtpId(otpResp.otpId);
        console.log('otpResp: ', otpResp);
        setIntialLoader(false);
        
        setShowOtpPopup(true);
      }
    }
  });

  const setOtpValue = (data) => {
    setOtpData(data);
    if (data?.value?.length === 6 && data?.isSubmit) {
      handleOtpConfirmClick();
    }
  };

  const handleOtpConfirmClick = () => {
    if (otpData?.value?.length === 6) {
      setOtpPopup(false);
      setShowOtpPopup(false);
      setTimeout(() => {
        focusMaskEle('cardNo');
      }, 200);
    }
  }

  const handleCardChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {
      setPartialCardNo(value);
      checkValidation({ partialCardNo: value });
      if (value.length === 4) {
        // cardExpiryMonthRefs.ele.focus();
      }
    }
  }

  const handleExpiryMonthChange = (event) => {
    let { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {
      if (Number(value) > 12 || (value.length === 2 && Number(value) === 0)) {
        setExpiryDateError('Please enter valid month');
      } else {
        setExpiryDateError('');
        if (Number(value) > 1 && Number(value) < 10) {
          value = '0' + Number(value);
        }
        setCardExpiryMonth(value);
        checkValidation({ cardExpiryMonth: value });
        if (value.length === 2) {
          // cardExpiryYearRefs.ele.focus();
        }
      }
    }
  }

  const handleMonthBlur = (event) => {
    let { value } = event.target;
    if (value?.length === 1 && Number(value) !== 0) {
      value = '0' + Number(value);
      setCardExpiryMonth(value);
      checkValidation({ cardExpiryMonth: value });
    }
  }

  const handleExpiryYearChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {
      if (value.length === 2 && Number(value) < Number(new Date().getFullYear().toString().slice(-2))) {
        setExpiryDateError('Please enter valid month and year');
      } else {
        setExpiryDateError('');
        setCardExpiryYear(value);
        checkValidation({ cardExpiryYear: value });
        if (value.length === 2) {
          // cardExpiryMonthRefs.ele.focus();
        }
      }
    }
  }


  const handleExpiryKeyUp = (event, type) => {
    const { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {

      if (type === 'cardNo' && partialCardNo.length === 4) {
        // cardExpiryMonthRefs.ele.focus();
      } else if (type === 'month' && cardExpiryMonth.length === 2) {
        // cardExpiryYearRefs.ele.focus();
      }
    }
  }

  const setMpinValue = (data) => {
    setMpinData(data);
    checkValidation({ mpinData: data?.value });
  };

  const setReMpinValue = (data) => {
    setReMpinData(data);
    checkValidation({ reMpinData: data?.value });
  };

  const focusMaskEle = (type) => {
    if (type === 'cardNo') {
      cardNoRefs.ele.focus();
    }
  };

  const checkValidation = (data, isShowToast) => {
    const validationData = {
      otpData: (data?.otpData) ? data?.otpData : otpData?.value,
      partialCardNo: (data?.partialCardNo) ? data?.partialCardNo : partialCardNo,
      cardExpiryMonth: (data?.cardExpiryMonth) ? data?.cardExpiryMonth : cardExpiryMonth,
      cardExpiryYear: (data?.cardExpiryYear) ? data?.cardExpiryYear : cardExpiryYear,
      mpinData: (data?.mpinData) ? data?.mpinData : mpinData?.value,
      reMpinData: (data?.reMpinData) ? data?.reMpinData : reMpinData?.value
    }
    showToast('');
    setValidData(false);
    setOtpError('');
    let isValid = true;
    if (validationData?.partialCardNo?.length !== 4) {
      isValid = false;
    }
    if (!validationData?.cardExpiryMonth || validationData?.cardExpiryMonth > 12) {
      isValid = false;
    } else if (!validationData?.cardExpiryYear || validationData?.cardExpiryYear < Number(new Date().getFullYear().toString().slice(-2))) {
      isValid = false;
    } else if (!validateMonthYear(validationData?.cardExpiryYear, validationData?.cardExpiryMonth)) {
      setExpiryDateError('Please enter valid month and year');
      isValid = false;
    }
    if (validationData?.mpinData?.length !== 4) {
      isValid = false;
    } else if (validationData?.reMpinData?.length !== 4) {
      isValid = false;
    } else {
      if (validationData?.mpinData !== validationData?.reMpinData) {
        console.log('Not same: ', validationData?.mpinData, ' : ', validationData?.reMpinData);
        setOtpError('Please enter m-pin and re-enter m-pin should same');
        isValid = false;
      } else {
        setOtpError('');
      }
    }
    if (isValid) {
      setValidData(true);
      console.log('validData true: ', validData);
    }
    console.log('validData: ', validData);
  }

  const validateMonthYear = (year, month) => {
    if (month?.length !== 2 || year?.length !== 2 || (Number(year) === Number(new Date().getFullYear().toString().slice(-2)) && Number(month) > new Date().getMonth())) {
      return false;
    }
    return true;
  }
  const handleForgotPin = useCallback(async () => {
    if (validData) {
      let payload = {
        mobileNumber: loginUserData.mobileNumber,
        partialCardNo: partialCardNo,
        expiryDate: cardExpiryYear + '' + cardExpiryMonth,
        pin: mpinData.value,
        otpId: otpId,
        otp: otpData.value
      }
      console.log('payload: ', payload);
      const forgotResp = await forgotMpin(payload);
      console.log('forgotResp: ', forgotResp);
      if (forgotResp?.status === 'error') {
        if (forgotResp?.error?.message) {
          showToast(forgotResp?.error?.message, 'danger');
        }
        if (forgotResp?.error?.code === 'OTP006') {
          setOtpPopup(true);
          setOtpData({});
          handleOtp();
        }
      } else {
        showToast('M-PIN successfully updated', 'success');
        setTimeout(() => {
          navigate('/');
        }, 500);
      }
    }
  })

  return (
    <>{isIntialLoader ? (<div className="loader">
      <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '40%' }} />
    </div>) : (
      <>
        <div className={isOtpPopup ? 'd-none' : 'text-center'}>
          <div className="forgot-mpin-wrapper">
            <p className='page-sub-title'>Enter the last 4 digits of your card number and expiry date to re-verify</p>
            <div className="">
              <div className="control-wrapper">
                <label className='control-label-wrapper'>Last 4 digits of your card</label>
                <div className='card-no-mask-wrapper'>
                  <div className='card-no-mask-container'>
                    <div className="card-mask-control-wrapper" onClick={() => focusMaskEle('cardNo')}>
                      <span className=''>XXXX</span>
                      <span className=''>XXXX</span>
                      <span className=''>XXXX</span>
                    </div>
                    <input
                      type="text"
                      maxLength="4"
                      value={partialCardNo}
                      onChange={(event) => handleCardChange(event)} onKeyUp={(event) => handleExpiryKeyUp(event, 'cardNo')}
                      ref={(el) => (cardNoRefs.ele = el)}
                      className="form-control" placeholder='XXXX'
                    />
                  </div>
                </div>
              </div>
              <div className="control-wrapper">
                <label className='control-label-wrapper'>Expiry date</label>
                <div className='card-expiry-mask-wrapper'>
                  <div className='card-expiry-mask-container'>
                    <input
                      type="text"
                      maxLength="2" placeholder={'MM'}
                      value={cardExpiryMonth}
                      onChange={(event) => handleExpiryMonthChange(event)} onKeyUp={(event) => handleExpiryKeyUp(event, 'month')}
                      onBlur={(event) => handleMonthBlur(event, 'month')}
                      ref={(el) => (cardExpiryMonthRefs.ele = el)}
                      className="form-control month-control"
                    />
                    <span className='mask-seperator'>/</span>
                    <input
                      type="text"
                      maxLength="2" placeholder={'YY'}
                      value={cardExpiryYear}
                      onChange={(event) => handleExpiryYearChange(event)} onKeyUp={(event) => handleExpiryKeyUp(event, 'year')}
                      ref={(el) => (cardExpiryYearRefs.ele = el)}
                      className="form-control"
                    />
                  </div>
                </div>
                {showExpiryDateError && <p className='error-text'>{showExpiryDateError}</p>}
              </div>
            </div>

            <div className="forgot-mpin-control-wrapper border-top pt-2">
              <EnterOtp label={'Enter M-PIN'} otpLength={4} controlType={'password'} setValue={setMpinValue} />
              <EnterOtp label={'Re-enter M-PIN'} otpLength={4} controlType={'password'} setValue={setReMpinValue} showOtpError={showOtpError} />
            </div>
          </div>

          <div className="page-footer-wrapper text-center">
            <button
              className="btn btn-primary btn-lg btn-circular page-footer-btn"
              disabled={!validData}
              onClick={handleForgotPin}
            >
              Continue
            </button>
          </div>
        </div>

        {isOtpPopup &&
          <>
            <div className={showOtpPopup ? 'modal-backdrop' : 'd-none'}></div>
            <div className={'modal bottom-custom-modal ' + (showOtpPopup ? 'open-modal' : '')}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title has-close-btn">Confirm OTP</h4>
                  </div>
                  <div className="modal-body">
                    <EnterOtp label={'Please enter the OTP sent to registered mobile number'} otpLength={6} setValue={setOtpValue} />
                  </div>
                  <div className='modal-footer'>
                    <button className="btn btn-primary btn-lg btn-circular" disabled={otpData?.value?.length !== 6} onClick={handleOtpConfirmClick}>Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    )}
    </>
  );
}
