import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/styles.scss';

export function Menu({ blockCardApiCall }) {
    const [buttonClick, setButtonClick] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const navigate = useNavigate();

    const handleRadioClick = () => {
        setButtonClick(true);
    }

    const handleBlockCard = async () => {
        try {
            await blockCardApiCall();
            setShowSuccessMessage(true);
        } catch (error) {
            console.log('Error:', error);
            setShowErrorMessage(true);
            return;
        }
        setTimeout(() => {
            setShowSuccessMessage(false);
            setShowErrorMessage(false);
            navigate(-1);
        }, 3000);
    };

    return (
        <div className="body-wrapper has-padding has-fixed-footer">
            <div className="body-container">
                <div className="page-title-wrapper">
                    <div className="page-title">Block your card permanently</div>
                </div>

                <div className="">
                    <label className="form-label">I want to block my card because:</label>
                    <div>
                        <div className="custom-radio-item-wrapper" >
                            <label className="form-check-radio" htmlFor="reason1">
                                <input type="radio" id="reason1" name="reason" className="form-check-input-radio" onClick={handleRadioClick} />
                                <span className="form-check-label-radio">
                                    Lost my card
                                </span>
                            </label>
                        </div>
                        <div className="custom-radio-item-wrapper">
                            <label className="form-check-radio" htmlFor="reason2">
                                <input type="radio" id="reason2" name="reason" className="form-check-input-radio" onClick={handleRadioClick} />
                                <span className="form-check-label-radio">
                                    Card security is compromised
                                </span>
                            </label>
                        </div>
                        <div className="custom-radio-item-wrapper" >
                            <label className="form-check-radio" htmlFor="reason3">
                                <input type="radio" id="reason3" name="reason" className="form-check-input-radio" onClick={handleRadioClick} />
                                <span className="form-check-label-radio">
                                    Card is damaged
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                {showSuccessMessage && (
                    // <div className="text-success">
                    //     <p>Your card is blocked successfully!</p>
                    // </div>
                    <div className="alert-item-wrapper success-alert mb-2">
                        <div className="alert-item-container">
                            <div className="alert-text">Your card is blocked successfully!</div>
                        </div>
                    </div>
                )}
                {showErrorMessage && (
                    <div className="alert-item-wrapper danger-alert mb-2">
                        <div className="alert-item-container">
                            <div className="alert-text">Failed to block the card. Please try again later.</div>
                        </div>
                    </div>
                    // <div className="text-danger">
                    //     <p>Failed to block the card. Please try again later.</p>
                    // </div>
                )}

            </div>

            <div className='page-footer-wrapper'>
                <div className='page-footer-container'>
                    <button className="btn btn-primary btn-lg btn-circular page-footer-btn" onClick={handleBlockCard} disabled={!buttonClick}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}