import React from 'react';
import { ApiCalls } from '../common/apiCalls';

import { Header } from './components/header';
import { Menu } from './components/menu.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export function StatementsIndex() {
    const { loading, previousStatements } = ApiCalls();
    return (
        <>
            <Header />
            {loading ? (
                <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '40%' }} />
                </div>
            ) : (
                <div className="body-wrapper has-sticky-header">
                    <div className="secondary-header-wrapper">
                        <div>View or download all your statements here</div>
                    </div>
                    <div className="body-container">
                        <Menu previousStatements={previousStatements} />
                    </div>
                </div>
            )}
        </>
    )
}
