import React, { createContext, useContext, useState } from 'react';

const SettingsContext = createContext();

export const useSettings = () => {
  return useContext(SettingsContext);
};

export const SettingsStateProvider = ({ children }) => {
  const [settingmodalOpen, setSettingModalOpen] = useState(false);

  return (
    <SettingsContext.Provider value={{ settingmodalOpen, setSettingModalOpen }}>
      {children}
    </SettingsContext.Provider>
  );
};