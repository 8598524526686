import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/styles.scss';

function Statement() {
    const navigate = useNavigate();

    const handleLatestStatement = () => {
        navigate('/statement-list')
    }

    return (
        <>
            <div className='custom-card-wrapper'>
                <div className='custom-card-container'>
                    <div className='custom-card-left-wrapper'>
                        <div className='custom-card-title'>Statements</div>
                        </div>
                    <div className='custom-card-right-wrapper'>
                        <button className="btn btn-lg btn-primary" onClick={handleLatestStatement}>View all</button>
                    </div>
                </div>
            </div>
            {/* <div className="status-wrapper">
                <button className="full-width-button" onClick={handleLatestStatement}>Latest Statement</button>
            </div> */}
        </>
    )
}

export default Statement