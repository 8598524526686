import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export function EmiTrack(props) {

    const [loading, setLoading] = useState(false);

    if (loading) {
        return <div className="loader">
          <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '40%'  }} />
              </div>; // Render loading indicator
      }

    return (
        <>
                    <div className="track-emi-tab">
            {/* <h3> Don't want to pay it all at once </h3> */}
            <p>Track transactions of easy EMIs. <a
                    href="">Learn more</a> </p>
        </div>
        </>
    )
}
