import React, { useState } from 'react';
import '../../assets/styles/styles.scss';
import { useCollapse } from 'react-collapsed';
import moment from 'moment';

function InnerCollapsibleTwo() {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    return (
        <div className="emi-cal-wrapper emi-cancellation">
            <div className={isExpanded ? 'emi-cal-header-wrapper emi-expanded' : 'emi-cal-header-wrapper'} {...getToggleProps()}>
                <div className='emi-cal-text'>{isExpanded ? 'EMI cancellation fee' : 'EMI cancellation fee'} <i className={'fa-solid fa-chevron-' + (isExpanded ? 'up' : 'down')}></i></div>
            </div >
            <div {...getCollapseProps()}>
                <div className='emi-cal-body'>
                    <table className='table table-sm1 table-bordered'>
                        <tbody>
                            <tr>
                                <td>Cancellation before 30 days</td>
                                <td className='emi-cal-table-value'>₹0</td>
                            </tr>
                            <tr>
                                <td>Cancellation after 30 days</td>
                                <td className='emi-cal-table-value'>2% of principal outstanding</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content">
                    <div className="d-flex justify-content-between">
                        <p>Cancellation before 30 days</p>
                        <p>₹0</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Cancellation after 30 days</p>
                        <p>2% of principal outstanding</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
function InnerCollapsible() {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    return (
        <div className="emi-cal-wrapper">
            <div className={isExpanded ? 'emi-cal-header-wrapper emi-expanded' : 'emi-cal-header-wrapper'} {...getToggleProps()}>
                <div className='emi-cal-text'>{isExpanded ? 'Total Payable ' : 'Total Payable '} <i className={'fa-solid fa-chevron-' + (isExpanded ? 'up' : 'down')}></i></div>
                <div className='emi-cal-action-wrapper'>
                    <div className='emi-cal-action-value'>₹26,806.58</div>
                </div>
            </div >
            <div {...getCollapseProps()}>
                <div className='emi-cal-body'>
                    <table className='table table-sm1 table-bordered'>
                        <tbody>
                            <tr>
                                <td>Transaction amount</td>
                                <td className='emi-cal-table-value'>₹26,046.00</td>
                            </tr>
                            <tr>
                                <td>Interest charged at <b>16% p.a.</b></td>
                                <td className='emi-cal-table-value'>₹545.56</td>
                            </tr>
                            <tr>
                                <td>GST on interest <b>(18%)</b></td>
                                <td className='emi-cal-table-value'>₹98.20</td>
                            </tr>
                            <tr>
                                <td>Processing fee</td>
                                <td className='emi-cal-table-value'>₹99.00</td>
                            </tr>
                            <tr>
                                <td>GST on Processing fee <b>(18%)</b></td>
                                <td className='emi-cal-table-value'>₹17.82</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
function Collapsible() {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ isExpanded: 'defaultExpanded' });
    console.log('getCollapseProps: ', getCollapseProps);
    const tenureList = [
        { id: '3', label: '3 months' },
        { id: '6', label: '6 months' },
        { id: '9', label: '9 months' },
        { id: '12', label: '12 months' }
    ];
    const [selectedTenure, setTenure] = useState(tenureList[1].id);


    const handleEmiTenure = (id) => {
        setTenure(id);
    };

    return (
        <div >
            <div {...getToggleProps()}>
                <div className='emi-card-header-wrapper'>
                    <div className='emi-text'>Easy EMI</div>
                    <div className="emi-action-wrapper">
                        <button className='btn'>
                            {isExpanded ? 'Compare Plans ' : 'Compare Plans'} <i className={'fa-solid fa-chevron-' + (isExpanded ? 'up' : 'down')}></i>
                        </button>
                    </div>
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className="emi-card-dropdown-section">
                    <p className='fw-600 mb-1'>Pay only &#8377;756.46 every month </p>
                    <div className="emi-list-wrapper py-2">

                        {tenureList.map((tenureItem, index) => (
                            <div className="emi-list-item">
                                <label className={`emi-tenure-wrapper ${selectedTenure === tenureItem.id?'active':''}`} htmlFor={tenureItem.id+'mRadio'} onClick={() => handleEmiTenure(tenureItem.id)}>
                                    <div className="form-check">
                                        <input type="radio" id={tenureItem.id+'mRadio'} name="emiTenure" className='form-check-input' checked={selectedTenure === tenureItem.id ? true : false} />
                                        <span className='form-check-label'>{tenureItem.label}</span>
                                    </div>
                                </label>
                            </div>
                        ))}
                        {/* <div className="emi-list-item">
                            <label className="emi-tenure-wrapper" htmlFor="3mRadio" onClick={() => handleEmiTenure('3mRadio')}>
                                <div className="form-check">
                                    <input type="radio" id="3mRadio" name="emiTenure" className='form-check-input' checked={selectedTenure === '3mRadio' ? true : false} />
                                    <span className='form-check-label'>3 months</span>
                                </div>
                            </label>
                        </div>
                        <div className="emi-list-item">
                            <label className="emi-tenure-wrapper" htmlFor="6mRadio" onClick={() => handleEmiTenure('6mRadio')}>
                                <div className="form-check">
                                    <input type="radio" id="6mRadio" name="emiTenure" className='form-check-input' checked={selectedTenure === '6mRadio' ? true : false} />
                                    <span className='form-check-label'>6 months</span>
                                </div>
                            </label>
                        </div>
                        <div className="emi-list-item">
                            <label className="emi-tenure-wrapper" htmlFor="9mRadio" onClick={() => handleEmiTenure('9mRadio')}>
                                <div className="form-check">
                                    <input type="radio" id="9mRadio" name="emiTenure" className='form-check-input' checked={selectedTenure === '9mRadio' ? true : false} />
                                    <span className='form-check-label'>9 months</span>
                                </div>
                            </label>
                        </div>
                        <div className="emi-list-item">
                            <label className="emi-tenure-wrapper" htmlFor="12mRadio" onClick={() => handleEmiTenure('12mRadio')}>
                                <div className="form-check">
                                    <input type="radio" id="12mRadio" name="emiTenure" className='form-check-input' checked={selectedTenure === '12mRadio' ? true : false} />
                                    <span className='form-check-label'>12 months</span>
                                </div>
                            </label>
                        </div> */}
                    </div>
                    {/* <div className="installments-wrapper">
                        <p className='installments-title'>Installments</p>
                        <div className='installments-value'>₹756.46<i className="fa-solid fa-xmark"></i>9</div>
                    </div> */}
                    <InnerCollapsible />
                    <InnerCollapsibleTwo />
                    <div className="terms-and-conditions-container">
                        <div className="form-check form-check-lg1">
                            <input type="checkbox" id="termsCheckbox" className='form-check-input' />
                            <label htmlFor="termsCheckbox" className='form-check-label'>I have read, understood, and accept the terms and conditions and the key fact sheet</label>
                        </div>
                    </div>
                    <div className="p-2">
                        <button className="btn btn-lg btn-primary btn-circular w-100">Convert to 9-month EMI</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function EmiTransaction(props) {
    const { transactionDetail = [] } = props;
    console.log('transactionDetail: ', props);

    return (
        <div className='transactions-wrapper'>

            <div className='statement-card-wrapper'>
                <div className='statement-card-container'>
                    <div className='statement-icon-wrapper'>
                        <div className='statement-icon-container'>
                            <i className="fa-regular fa-credit-card"></i>
                        </div>
                    </div>
                    <div className='statement-card-content-wrapper'>
                        <div className="statement-duration">{moment(transactionDetail?.transactionDate).format('MMMM Do YYYY, h:mm:ss a')}</div>
                        <div className='statement-card-value-wrapper'>
                            {transactionDetail?.amount &&
                                <div className="statement-text-wrapper">&#8377;{transactionDetail?.amount}</div>
                            }
                            <div className='statement-card-status-wrapper'></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="transaction-label-wrapper">
                <div className='transaction-label-container'>
                    <p className="transaction-label-title">Transaction status</p>
                    <p className="transaction-label-value danger-text">{transactionDetail?.authorizationStatus}</p>
                </div>
                <p className='transaction-label-description'>(Usually takes 2-3 working days to reflect in your account)</p>
            </div>

            <div className="transaction-label-wrapper">
                <div className='transaction-label-container'>
                    <p className="transaction-label-title">Billing status</p>
                    <p className="transaction-label-value label-title">{transactionDetail?.billedStatus}</p>
                </div>
            </div>

            <div className="transaction-label-wrapper">
                <div className='transaction-label-container'>
                    <p className="transaction-label-title">Transaction ID</p>
                    <p className="transaction-label-value label-title">{transactionDetail?.externalTransactionId}
                        <button className='btn btn-link'><i className="fa-regular fa-copy copy-icon"></i></button>
                    </p>
                </div>
            </div>

            <div>

                <div>
                    <div className="emi-card-wrapper">
                        <div className="emi-card-container">
                            <Collapsible />
                        </div>
                        <div className="emi-card-footer">
                            <i className="fa-regular fa-clock"></i>&nbsp;&nbsp;Avail EMI once transaction is settled. It can take 2-3 working days.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}