import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../assets/styles/styles.scss';

export function MenuList({ isCardLocked, lockCardApiCall, unlockCardApiCall, onLockStatusChange }) {
    console.log(isCardLocked)
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    // const [lockUnlockText, setLockUnlockText] = useState(isCardLocked ? 'Unlock' : 'Lock');
    const [customErrorMessage, setCustomErrorMessage] = useState("");    
    const [modalText, setModalText] = useState(isCardLocked ? 'UnLock' : 'Lock');

    useEffect(() => {
        setModalText(isCardLocked ? 'Unlock' : 'Lock');
    }, [isCardLocked]);

    const handleChangeBilling = () => {
        navigate('/change-billing');
    };

    const handleLockCardClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        setCustomErrorMessage("");
    };

    const handleLockCard = async () => {
        try {
            if (isCardLocked) {
                await unlockCardApiCall();
                setShowSuccessMessage(true);
                onLockStatusChange(false); // Card is now unlocked
            } else {
                await lockCardApiCall();
                setShowSuccessMessage(true);
                onLockStatusChange(true); // Card is now locked
            }
        } catch (error) {
            console.log('Error:', error);
            setShowErrorMessage(true);
            return;
        }
        setTimeout(() => {
            setShowSuccessMessage(false);
            setShowErrorMessage(false);
        }, 3000);
        setShowModal(false);
    };
    const handlePinReset = () => {
        if (modalText === "Lock") {
            navigate('/pin-reset');
        } else {
            setCustomErrorMessage("Please unlock your card to avail this service");
            setTimeout(() => {
                setCustomErrorMessage("");
            }, 5000);
        }
    };

    const handleLimitControl = () => {
        navigate('/limit-control');
    };

    const handleBlockCard = () => {
        navigate('/block-card')
    };

    const handleCardClosure = () => {
        navigate('/card-closure')
    };

    const handleAboutCard = () => {
        navigate('/about-card')
    }

    return (
        <div className="sidebar-menu-wrapper">
            <div className="sidebar-menu-body">
                <div className="float-menu-item-wrapper" onClick={handleLockCardClick} >
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-lock"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc">{modalText} your card</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                {showModal && (
                    <>
                        <div className="modal-backdrop" onClick={closeModal}></div>
                        <div className="modal bottom-custom-modal open-modal">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title has-close-btn">{modalText} your card</h4>
                                        <button type="button" aria-label="Close" className="btn modal-close" onClick={closeModal}>
                                            <i className="feather-x-circle"></i>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p className='text-center p-3'>{isCardLocked ? 'Unlocking' : 'Locking'} your card will {isCardLocked ? 'enable' : 'disable'} all future transactions and auto-payments. Do you wish to continue?</p>
                                    </div>
                                    <div className='modal-footer'>
                                        <button className="btn btn-primary btn-lg btn-circular footer-close-btn1" onClick={handleLockCard}>Yes, {modalText} my card</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {showSuccessMessage && !showErrorMessage && (
                    <div className="text-success">
                        <p>Your card is successfully {isCardLocked ? 'locked' : 'Unlocked'}!</p>
                    </div>
                )}
                {showErrorMessage && (
                    <div className="alert-item-wrapper danger-alert mb-2">
                        <div className="alert-item-container">
                            <div className="alert-text">{isCardLocked ? 'Failed to lock' : ' Failed to unlock'} the card. Please try again later.</div>
                        </div>
                    </div>
                )}

                <div className="float-menu-item-wrapper" onClick={handleLimitControl}>
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="fa-sliders fa-solid"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc" >Set limit and permissions</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handlePinReset}>
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-more-horizontal"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc" >Change your POS/ATM PIN</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handleChangeBilling}>
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-calendar"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc" >Change billing Cycle </div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handleBlockCard}>
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="fa-ban fa-solid"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc">Block your card</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handleCardClosure} >
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-x-circle"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc">Close your card</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handleAboutCard} >
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-alert-circle"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc">Know your fees and charges</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                {customErrorMessage && (
                    <div className='p-2'>
                    <div className="alert-item-wrapper danger-alert">
                        <div className="alert-item-container">
                            <div className="alert-text">{customErrorMessage}</div>
                        </div>
                    </div>
                    </div>
                )}
            </div>
        </div>
    )
}