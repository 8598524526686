import React, { useEffect } from "react";
import Header from "./components/header.js";
import Card from "./components/card.js";
import Statement from "./components/statement.js";
import Transactions from "./components/transactions.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "../assets/styles/styles.scss";
import { ApiCalls } from "../common/apiCalls.js";

import { useSettings } from "../common/settingState.js";

export function CardIndex() {
    const {setButtonClick, cardBalance, cardDetails, unbilledTransactions, limitDetails, cvvDetails, loading } = ApiCalls();
    const {settingmodalOpen, setSettingModalOpen} = useSettings();
    useEffect(() => {
        const preventBackNavigation = () => {
            window.history.pushState(null, null, window.location.pathname);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };

        preventBackNavigation();

        return () => {
            window.onpopstate = null;
        };
    }, []);

    return (
        <>
            <Header cardDetails={cardDetails} setSettingModalOpen={setSettingModalOpen} settingmodalOpen={settingmodalOpen} setButtonClick={setButtonClick} />
            <div className="body-wrapper has-curved">
                <div className="body-container">
                    {loading ? (<div className="loader">
                        <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '40%'  }} /> 
                    </div>) : (<><Card balance={cardBalance} cardDetails={cardDetails} limitDetails={limitDetails} cvvDetails={cvvDetails} />
                        <Statement />
                        <Transactions unbilledTransactions={unbilledTransactions}  />
                    </>)}
                </div>
            </div>
        </>
    );
}
