import React, { useState, useEffect } from 'react';

import { SettingsHeader } from './components/settingheader';
import { MenuList } from './components/menu-list';
import { ApiCalls } from '../common/apiCalls';

export function SettingsIndex({ onClose }) {
    const { cardDetails, lockCardApiCall, unlockCardApiCall } = ApiCalls();

    const [isCardLocked, setIsCardLocked] = useState(false);

    useEffect(() => {
        if (cardDetails?.card?.status === 'ALLOCATED') {
            setIsCardLocked(false);
        } else {
            setIsCardLocked(true);
        }
    }, [cardDetails]);

    const handleLockStatusChange = (newStatus) => {
        setIsCardLocked(newStatus);
    };

    return (
        <div className="sidebar-wrapper fullpage">
            <div className="sidebar-container">
                <SettingsHeader onClose={onClose} />
                <div className="sidebar-body-wrapper">
                    <div className="secondary-header-wrapper">
                        <div> Manage your card settings here </div>
                    </div>
                    <MenuList
                        isCardLocked={isCardLocked}
                        lockCardApiCall={lockCardApiCall}
                        unlockCardApiCall={unlockCardApiCall}
                        onLockStatusChange={handleLockStatusChange}
                    />
                </div>
            </div>
            <div className="sidebar-overlay" onClick={onClose}></div>
        </div>
    );
}

