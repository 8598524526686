export function SettingsHeader(props) {
    const { onClose } = props;

    return (
        <>
        <div className="header-wrapper back-header">
            <div className="header-container">
                <div className="header-menu-wrapper">
                    <button className="btn" onClick={onClose}>
                        <i className="feather-arrow-left"></i>
                    </button>
                </div>
                <div className="header-title">Card Controls</div>
                <div className="header-right-wrapper"></div>
            </div>
        </div>
        </>
    )
}