import { Header } from '../limit-Controls/components/header';
import CLC from './components/clc';

export function LimitControlsIndex() {

    return (
        <>
            <Header />
            <CLC />
        </>
    )
}
