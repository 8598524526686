import React from 'react';
import { Header } from './components/header';
import { AboutCard } from './components/about-card-details';

export function AboutCardIndex() {
    

    return (
        <>
            <Header />
            <AboutCard />
        </>
    )
}
