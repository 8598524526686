import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { cardClose } from '../../../common/cms-sdk/index';

export function CardClosure(props) {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');
    const [countdownMessage, setCountdownMessage] = useState('');
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);

    useEffect(() => {
        setIsOptionSelected(selectedOption !== '');
    }, [selectedOption]);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSubmitButtonClick = () => {
        setIsButtonClicked(true);
        const timer = setTimeout(() => {
            setCountdownMessage("Request submitted successfully");
            setTimeout(() => {
                navigate(-1);
            }, 7000);
        }, 1000);
        return () => clearTimeout(timer);
    }

    // const handleContactSupportClick = () => {
    //     console.log("Submitting request with reason: ", selectedOption);
    //     setCountdownMessage("Thank you, Our support team will contact you in a while");
    //     const timer = setTimeout(() => {
    //         navigate(-1);
    //     }, 7000);
    //     return () => clearTimeout(timer);
    // };

    const handleContactSupportClick = async () => {
        try {
            // Call cardClose function with selected option as the body
            const response = await cardClose({ reason: selectedOption });
            // Check if the request was successful
            if (response === true) {
                setCountdownMessage("Request submitted successfully");
                setTimeout(() => {
                    navigate(-1);
                }, 3000);
            }
            // else {
            //     console.log("Request failed:", response);
            //     setCountdownMessage("Failed to submit request. Please try again.");
            // }
        } catch (error) {
            console.log("Error submitting request:", error);
            setCountdownMessage("Failed to submit request. Please try again.");
        }
    };

    return (
        <>
            <div className="body-wrapper has-padding has-fixed-footer">
                <div className="body-container">
                    <div className="page-title-wrapper">
                        <div className="page-title">Submit close card request?</div>
                        <div className="page-description">Dear customer, your satisfaction is important to us. If you still choose to close your card, please select a reason and our customer support team will assist you </div>
                    </div>

                    <div className="">
                        <button className="btn toggle-btn" type="button" onClick={toggleDropdown}>
                            <span className='btn-toggle-text'>Tell us what is not working for you</span>
                            <span className='btn-toggle-icon'>
                                {isDropdownOpen ? (
                                    <i className="feather-chevron-up"></i>
                                ) : (
                                    <i className="feather-chevron-down"></i>
                                )}
                            </span>
                        </button>
                        {isDropdownOpen && (
                            <>
                                <div>
                                    <div className="custom-radio-item-wrapper" >
                                        <label className="form-check-radio" htmlFor="rewardSystem">
                                        <input type="radio" name="closureReason" id="rewardSystem" className="form-check-input-radio"
                                            onClick={() => handleOptionChange("I don't like the coin reward system")} />
                                        <span>I don't like the coin reward system</span>
                                    </label>
                                    </div>
                                    <div className="custom-radio-item-wrapper" >
                                        <label className="form-check-radio" htmlFor="secureCard">
                                        <input type="radio" name="closureReason" id="secureCard" className="form-check-input-radio"
                                            onClick={() => handleOptionChange("I don't find this card to be secure and safe")} />
                                        <span>I don't find this card to be secure and safe</span>
                                    </label>
                                    </div>
                                    <div className="custom-radio-item-wrapper" >
                                        <label className="form-check-radio" htmlFor="betterCard">
                                        <input type="radio" name="closureReason" id="betterCard" className="form-check-input-radio"
                                            onClick={() => handleOptionChange("I have found a better card")} />
                                        <span>I have found a better card</span>
                                    </label>
                                    </div>
                                    <div className="custom-radio-item-wrapper" >
                                        <label className="form-check-radio" htmlFor="highCharges">
                                        <input type="radio" name="closureReason" id="highCharges" className="form-check-input-radio"
                                            onClick={() => handleOptionChange("Card charges are too high")} />
                                        <span>Card charges are too high</span>
                                    </label>
                                    </div>
                                    <div className="custom-radio-item-wrapper" >
                                        <label className="form-check-radio" htmlFor="noOffers">
                                        <input type="radio" name="closureReason" id="noOffers" className="form-check-input-radio"
                                            onClick={() => handleOptionChange("Card does not offer on other apps")} />
                                        <span>Card does not offer on other apps</span>
                                    </label>
                                    </div>
                                    <div className="custom-radio-item-wrapper" >
                                        <label className="form-check-radio" htmlFor="notListed">
                                        <input type="radio" name="closureReason" id="notListed" className="form-check-input-radio"
                                            onClick={() => handleOptionChange("Option is not listed here")} />
                                        <span>Option is not listed here</span>
                                    </label>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {isDropdownOpen && (
                        <div className="footer-fixed-wrapper text-center">
                            {/* <button className="btn btn-primary btn-lg btn-circular footer-submitReq-btn" disabled={!isOptionSelected} onClick={handleSubmitButtonClick}>
                            Submit Request Form
                        </button> */}
                        </div>
                    )}

                    <div className="">
                        {countdownMessage && <div className="text-success">{countdownMessage}</div>}
                    </div>
                </div>
                <div className='page-footer-wrapper'>
                    <div className='page-footer-container'>
                        <button className="btn btn-primary btn-lg btn-circular page-footer-btn" onClick={handleContactSupportClick} disabled={!isOptionSelected}>
                            Contact Customer Support
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
