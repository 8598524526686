import React, { useState, useRef } from "react";
import "../../assets/styles/styles.scss";

export function ResetMpin() {

  const [cMPin, setCMPin] = useState(["", "", "", ""]);
  const [mPin, setMPin] = useState(["", "", "", ""]);
  const [mPinReEntry, setMPinReEntry] = useState(["", "", "", ""]);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const reEntryInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];
  
  const handleCPinChange = (event, index) => {
    const newMPin = [...cMPin];
    if (/^\d*$/.test(event.target.value) && event.target.value.length <= 1) {
      newMPin[index] = event.target.value;
      setCMPin(newMPin);
      if (index > 0 && event.target.value === "") {
        inputRefs[index - 1].current.focus();
      } else if (index < 3 && event.target.value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handlePinChange = (event, index) => {
    const newMPin = [...mPin];
    if (/^\d*$/.test(event.target.value) && event.target.value.length <= 1) {
      newMPin[index] = event.target.value;
      setMPin(newMPin);
      if (index > 0 && event.target.value === "") {
        inputRefs[index - 1].current.focus();
      } else if (index < 3 && event.target.value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleReEntryPinChange = (event, index) => {
    const newMPinReEntry = [...mPinReEntry];
    if (
      /^\d*$/.test(event.target.value) &&
      event.target.value.length <= 1
    ) {
      newMPinReEntry[index] = event.target.value;
      setMPinReEntry(newMPinReEntry);
      if (index > 0 && event.target.value === "") {
        reEntryInputRefs[index - 1].current.focus();
      } else if (index < 3 && event.target.value !== "") {
        reEntryInputRefs[index + 1].current.focus();
      }
    }
  };

  const handleSetPin = () => {
    const pin = mPin.join("");
    const reEnteredPin = mPinReEntry.join("");
    if (pin === reEnteredPin && pin.length === 4) {
      setSuccessMsg("M-PIN updated successfully!");
      setErrorMsg("");
    } else {
      setSuccessMsg("");
      setErrorMsg(
        "M-PIN & Re-entered M-PIN doesn't match. Please try again !!"
      );
    }
  };

  const arePinsFilled = cMPin.every((digit) => digit !== "") && mPin.every((digit) => digit !== "") && mPinReEntry.every((digit) => digit !== "");

  return (
    <>
      <div className="m-pin-wrapper">
        <div className="set-pin-container">
          <div className="set-pin-item-wrapper">
            <label>Current M-Pin</label>
            <div className="set-pin-item-container">
              {cMPin.map((digit, index) => (
                <div className="pin-item" key={index}>
                  <input
                    type="numeric"
                    value={digit ? '*' : ''}
                    onChange={(event) => handleCPinChange(event, index)}
                    ref={inputRefs[index]}
                    className="form-control"
                    inputMode="numeric"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="set-pin-item-wrapper">
            <label>Enter new M-Pin</label>
            <div className="set-pin-item-container">
              {mPin.map((digit, index) => (
                <div className="pin-item" key={index}>
                  <input
                    type="numeric"
                    value={digit ? '*' : ''}
                    onChange={(event) => handlePinChange(event, index)}
                    ref={inputRefs[index]}
                    className="form-control"
                    inputMode="numeric"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="set-pin-item-wrapper">
            <label>Re-enter the new M-Pin</label>
            <div className="set-pin-item-container">
              {mPinReEntry.map((digit, index) => (
                <div className="pin-item" key={index}>
                  <input
                    type="numeric"
                    maxLength="1"
                    value={digit ? '*' : ''}
                    onChange={(event) => handleReEntryPinChange(event, index)}
                    ref={reEntryInputRefs[index]}
                    className="form-control"
                    inputMode="numeric"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="set-pin-item-wrapper set-pin-btn-wrapper">
            {/* {successMsg && <div className="text-success">{successMsg}</div>}
              {errorMsg && <div className="text-danger">{errorMsg}</div>} */}

            {successMsg &&
              <div className="alert-item-wrapper success-alert mb-2">
                <div className="alert-item-container">
                  <div className="alert-text">{successMsg}</div>
                </div>
              </div>
            }

            {errorMsg &&
              <div className="alert-item-wrapper danger-alert mb-2">
                <div className="alert-item-container">
                  <div className="alert-text">{errorMsg}</div>
                </div>
              </div>
            }

          </div>
        </div>
        <div className="page-footer-wrapper">
          <button
            className="btn btn-primary btn-lg btn-circular page-footer-btn"
            onClick={handleSetPin}
            disabled={!arePinsFilled || cMPin.join("").length !== 4 || mPin.join("").length !== 4}
          >
            Update M-PIN
          </button>
        </div>
      </div>
    </>
  );
}